export function GoogleIcon({ className, disabled }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0009 5C13.6177 5 15.1022 5.55353 16.2872 6.47406L19.9244 3.00409C17.8097 1.13995 15.0416 0 12.0009 0C7.39331 0 3.39758 2.59991 1.38672 6.40985L5.43115 9.60278C6.41089 6.91937 8.97839 5 12.0009 5Z"
        fill={disabled ? '#979797' : '#F44336'}
      />
      <path
        d="M23.8961 13.5018C23.9586 13.0102 24 12.5087 24 12C24 11.1422 23.9063 10.3068 23.7352 9.5H12V14.5H18.4862C17.9615 15.8638 17.0272 17.0178 15.838 17.8195L19.8975 21.0243C22.0494 19.1354 23.522 16.4904 23.8961 13.5018Z"
        fill={disabled ? '#979797' : '#2196F3'}
      />
      <path
        d="M5 12.0003C5 11.1569 5.15686 10.3519 5.43024 9.60309L1.3858 6.41016C0.504333 8.08032 0 9.98047 0 12.0003C0 13.9976 0.495056 15.8766 1.35822 17.5333L5.40778 14.3362C5.14844 13.6047 5 12.8207 5 12.0003Z"
        fill={disabled ? '#979797' : '#FFC107'}
      />
      <path
        d="M11.9992 19C8.95367 19 6.36963 17.0515 5.40698 14.3359L1.35742 17.533C3.35846 21.3735 7.36902 24 11.9992 24C15.027 24 17.788 22.8752 19.8967 21.0243L15.8372 17.8195C14.7404 18.5589 13.4276 19 11.9992 19Z"
        fill={disabled ? '#979797' : '#00B060'}
      />
      <path
        opacity="0.1"
        d="M11.9993 23.7504C8.46765 23.7504 5.29205 22.2932 3.04688 19.9717C5.24469 22.4382 8.43579 24.0004 11.9993 24.0004C15.5299 24.0004 18.6946 22.469 20.8875 20.0411C18.6489 22.325 15.4974 23.7504 11.9993 23.7504Z"
        fill="black"
      />
      <path opacity="0.1" d="M12 14.25V14.5H18.4862L18.5875 14.25H12Z" fill="black" />
      <path
        d="M23.9942 12.1475C23.995 12.0982 23.9998 12.0498 23.9998 12.0004C23.9998 11.9865 23.9976 11.973 23.9975 11.959C23.9968 12.022 23.9937 12.0842 23.9942 12.1475Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.2"
        d="M12 9.5V9.75H23.7856C23.7698 9.66748 23.7526 9.58191 23.7352 9.5H12Z"
        fill="white"
      />
      <path
        d="M23.7352 9.5H12V14.5H18.4862C17.4775 17.1216 14.9772 19 12 19C8.13403 19 5 15.866 5 12C5 8.13397 8.13403 5 12 5C13.4019 5 14.6939 5.43066 15.7885 6.14069C15.9561 6.24957 16.1289 6.35181 16.2863 6.47406L19.9235 3.00409L19.8414 2.94098C17.7369 1.11707 15.0035 0 12 0C5.37256 0 0 5.37256 0 12C0 18.6274 5.37256 24 12 24C18.1177 24 23.1555 19.4188 23.8961 13.5018C23.9586 13.0102 24 12.5087 24 12C24 11.1422 23.9063 10.3068 23.7352 9.5Z"
        fill="url(#paint0_linear_709_1840)"
      />
      <path
        opacity="0.1"
        d="M15.7885 5.8905C14.6939 5.18048 13.4019 4.74982 12 4.74982C8.13403 4.74982 5 7.88379 5 11.7498C5 11.792 5.00057 11.825 5.0013 11.867C5.06874 8.05933 8.17621 4.99982 12 4.99982C13.4019 4.99982 14.6939 5.43048 15.7885 6.1405C15.9561 6.24939 16.1289 6.35162 16.2863 6.47388L19.9235 3.00391L16.2863 6.22388C16.1289 6.10162 15.9561 5.99939 15.7885 5.8905Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M12 0.25C14.975 0.25 17.6829 1.34839 19.7793 3.1416L19.9235 3.00409L19.8134 2.90827C17.709 1.08436 15.0035 0 12 0C5.37256 0 0 5.37256 0 12C0 12.0422 0.0058594 12.0829 0.0062866 12.125C0.0740356 5.55585 5.41473 0.25 12 0.25Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_709_1840"
          x1="0"
          y1="12"
          x2="24"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
